import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numberBriefs',
    standalone: true
})
export class NumberBriefsPipe implements PipeTransform {
  transform(value: number): string {
    if (value) {
      if (value > 999 && value <= 9999) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      if (value > 9999 && value <= 999999) {
        return ~~(value % 1000) === 0 ? `${value / 1000}K` : `${(value / 1000).toFixed(1)}K`;
      }
      if (value > 999999) {
        return ~~(value % 1000000) === 0 ? `${value / 1000000}m` : `${(value / 1000000).toFixed(1)}m`;
      }
      return value.toString();
    }
    return `${value}`;
  }
}
